div.app {
}

footer#footer {
    flex: 0 1 75px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #343a40;
    color: #fff;
}

footer#footer a {
    color: #fff;
}

#loading {
    flex: 1;

    display: flex;
    flex-direction: column;

    position: absolute;

    top:0;
    bottom: 0;
    left: 0;
    right: 0;
}

#loading .main {
    display: flex;
    flex-direction: column;

    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
}

#logo {
    width: 260px;
    height: 99px;
    text-indent: -9999px;
    background: url("images/logo.webp");
}

a {
    color: #000;
}