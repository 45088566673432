#container {
    flex: 1;

    display: flex;
    flex-direction: column;

    position: absolute;

    top:0;
    bottom: 0;
    left: 0;
    right: 0;
}

#top-menu {
    flex: 0 1 75px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #343a40;
    color: #fff;
    padding: 5px 0 10px;
}

#bottom-menu {
    flex: 0 1 75px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-top: 1px solid #CCC;
}

#content {
    display: flex;
    flex-direction: column;

    flex: 1;
    justify-content: center;
    align-items: center;

    overflow: auto;
}

#content .container {
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: overlay;
}

#logout {
    margin: 0 20px 0 auto;
}

#logo-min {
    width: 131px;
    height: 50px;
    text-indent: -9999px;
    background: url("../images/logo-min.png");
    margin: 0 auto 0 20px;
}

.image-preview {
    text-indent: -9999px;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    height: 200px;
    background-position: center;
    background-size: cover;
}

.buttons {
    margin-top: 20px;
}

.coffee-top-img {
    width: 100%;
    max-width: 800px;
    height: 200px;
    background-position: center;
    background-size: cover;
}